import React from "react";
import { Link } from "gatsby";

export default function Cta() {
  return (
    <section id='cta' className='cta mt-5'>
      <div className='container'>
        <div className='row g-5'>
          <div className='col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first'>
            <h3> Tax and GST Filing Service</h3>
            <p>
              One of the best Fast and Secure Online Tax Filing Service in Hosur
            </p>
            <Link className='cta-btn align-self-start' to='tel:+919585055661'>
              Call To Action
            </Link>
          </div>

          <div className='col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center'>
            <div className='img'>
              <img
                src='/imgs/cta.png'
                alt='Todayfilings'
                className='img-fluid'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
