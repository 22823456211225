import React from 'react';
// import PriceDetails from './GSTTemp/PriceDetails';
import Animation from './HomeComponents/Animation';

export default function About({ about_data }) {
    return (
      <div id='rs-about' className='rs-about style6 mt-5 pt-50 pb-100 '>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 align-items-center'>
              <div className='sec-title mb-26'>
                <h2 className='title extra-bold text-primary text-center'>
                  {about_data.title}
                </h2>
                <div className='sub-title text-center mt-5 text-capitalize'>
                  {about_data.sub_title}
                </div>
                {/* <div className='sub-div'>
                                <ul>
                                    <h4 className="title  text-center">
                                        {about_data.header}
                                    </h4>
                                    <br />
                                    {about_data.points.map((point, each_point) => (
                                        <h6 key={each_point}>
                                            <div className='row'>
                                                <div className='col-md-1'><i className="bi bi-check-circle-fill"></i></div>
                                                <div className='col-md-11'>{point}</div>
                                            </div>
                                        </h6>
                                    ))}

                                </ul>

                            </div> */}
              </div>
            </div>
            <div className='col-lg-5 pr-10 md-mb-40'>
              <p className='desc'>{about_data.paragraph}</p>
              {/* {about_data.paragraph.map((point, index) => (
                            <p className="desc" key={index}>
                                {point}
                            </p>
                        ))} */}
            </div>
            {/* <PriceDetails PricePackage={about_data.price} /> */}
            <div className='col-lg-4 pr-10 md-mb-10'>
              <div>
                <ul>
                  <h4 className='title  text-center'>{about_data.header}</h4>
                  <br />
                  {about_data.points.map((point, each_point) => (
                    <h6 key={each_point}>
                      <div className='row'>
                        <div className='col-md-1'>
                          <i className='bi bi-check-circle-fill'></i>
                        </div>
                        <div className='col-md-11'>{point}</div>
                      </div>
                    </h6>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='shape-animate mt-2'>
            <div className='transparent  blink'>
              {/* <img src="/imgs/ban2.png" alt="images" /> */}
              <p className='text-primary'>
                <a href='tel:+919585055661'>
                  Call for Consultation - +91 95850 55661
                </a>
              </p>
            </div>
            <div className='transparent left'>
              <img src='/imgs/ban1.png' alt='images' />
            </div>
            <div className='transparent right'>
              <img src='/imgs/ban2.png' alt='images' />
            </div>
          </div>
        </div>
        <Animation />
      </div>
    );
}
