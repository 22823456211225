import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import Service from "../../components/Services";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function LimitedLiabilityCompany() {
  /* Slider */
  // const limitedSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 20,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Limited Liability Partnership Registration Service`,
  //     content: `Apply for Limited Liability Partnership Registration Online with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/llp-slider.png",
  //     alt_tag: "Best Online limited liability partnership Registration in Hosur",
  //   },

  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Limited Liability Company`,
    buyBtnLink: `#pricing-buy`,
    includes: true,
    orginal: false,
    price: `7999`,
    f_price: `12999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/bregistration/pancard.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/bregistration/register.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `1yr
        hosting + 10 emails`,
        icon: '/imgs/icons/hosting.png',
        alt_tag: 'Domain Name',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `LLP Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const LLPAboutData = {
    title: `Limited Liability Partnership`,
    sub_title: `Online LLP Registration`,
    paragraph: `One of the most popular types of businesses for
    entrepreneurs is the limited liability partnership, or LLP.
    LLPs are the simplest type of business organization due to
    the benefit of restricted liability. Limited Liability
    Partnership registration in India allows partners complete
    freedom to create a partnership business structure in which
    each member's or partner's liability is constrained to the
    amount of capital they contribute to the enterprise. To be
    clear, if the partnership is terminated Affairs.`,
    header: `Our Company Registration Package Includes`,
    points: [
      `Features of LLP Registration`,
      `Minimum Capital Contribution is Not Necessary.`,
      `Minimal Initial Costs For An LLP `,
      `Less Compliances`,
    ],
  };
  /* Service data */
  const PvtLtdComp = {
    header: `Limited Liability Partnership Registration in India`,
    content: `Compared to a partnership firm, a limited liability
    partnership, or LLP, is a corporate structure with more added
    benefits.`,
    image: '/imgs/gifs/llp-1.gif',
    alt_img: 'TODAYFILINGS Online Limited Liability partnership Registration',
    points: [
      `Limited Responsibility`,
      `Distinct Legal Entity`,
      `Flexible Agreement`,
      `Simple To Wind-Up`,
      `No Owner-Manager Comparison`,
      `Required Few Compliance`,
    ],
  };

  /* FaQ */
  var LtdFAQ = [
    {
      header: 'What is DSC?',
      body: [
        {
          content: `The DSC is nothing but a Digital Signature Certificate issued by
          certifying authorities (TCS and n-Code) using that certificate you
          can sign electronic documents. As all documents need a DSC to start
          up a Private Limited company in India.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What is DIN?',
      body: [
        {
          content: `DIN is a short form of Director Identification Number and it is
          issued by Registration of the Companies ROC which permits the
          director to work in the company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is meant by Contribution in LLP?',
      body: [
        {
          content: `The Contribution in LLP means the share of the partners in the form
          of money, promissory notes, tangible or intangible property,
          agreements, movable or immovable assets to the Company.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'How many partners are required to register LLP?',
      body: [
        {
          content: `As per the LLP Act, 2008, a minimum of two partners is needed to
          register an LLP.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header:
        'Who can become a partner in a limited liability partnership firm?',
      body: [
        {
          content: `Any individuals, company or foreign national can become a partner in
          an LLP.`,
          icon: false,
        },
      ],
    },
  ];

  /* Tab Data */
  const LlpPartnershipTabsData = {
    id: 'features',
    heading: 'Registration Procedure for Limited Liability Partnership',
    paragraph: `An easy to follow step-by-step approach is provided below to
    help you register an LLP in India:`,
    body: [
      {
        tab_title: 'DIN And DSC',
        tab_content: [
          {
            content_title: 'Digital signature certificate(DSC)',
            content_paragraph: `You must apply for the DSC, or digital signature
          certificate, of the authorized partners of the LLP before
          the registration process may begin.`,
            points: [
              `The digital
            equivalent of physical or paper certificates.`,
            ],
          },
          {
            content_title: 'Director Identification Number(DIN)',
            content_paragraph: `All designated partners, or any partners who want to be
          designated partners of the LLP, must file an application
          for a Director Identification Number, or DIN.`,
            points: [
              `DIN is a unique Director Identification number allotted by the Central Government to any person intending to be a Director or an existing director of a company.`,
            ],
          },
        ],
        content_img: '/imgs/business/dsc1-lib.png',
        alt_img: 'Best Limited Liability Partnership Registration Services',
      },

      {
        tab_title: 'Name Reservation',
        tab_content: [
          {
            content_title: 'LLP name reservation',
            content_paragraph: `For the prospective LLP's name reservation, which will be
          handled by the Central Registration Center under Non-STP,
          a form known as the LLP-RUN or LLP-Reserve Unique Name is
          filed.`,
            points: [
              `The first step to incorporate Limited liability
            partnership (LLP) is reservation of name of LLP.`,
            ],
          },
          {
            content_title: 'Ministry Of Corporate Affairs',
            content_paragraph: `However, it is always advisable to check the MCA portal to
          see if the suggested or proposed name is already being
          used by another current company before submitting it.`,
            points: [
              `The MCA portal services has to register either as a registered
            user or as a business user.`,
            ],
          },
        ],
        content_img: '/imgs/business/name-reservation.png',
        alt_img: 'TODAYFILINGS - Online LLP Name Reservation',
      },
      {
        tab_title: 'LLP Incorporation',
        tab_content: [
          {
            content_title: 'LLP incorporation',
            content_paragraph: [],
            points: [
              `The LLP incorporation form must be filed with the
            Registrar of the state where the LLP's registered office
            is situated, along with all required supporting
            documentation.`,
              `PAN is for The LLP registration fees must be paid in accordance
            with Annexure "A".`,
              `The entity can use this form to apply for the DPIN
            allocation if the individual who is to be nominated as a
            designated partner does not already have a DIN or DPIN.`,
              `Only two people should submit the allocation
            application.`,
              `FiLLiP can also be used to submit a reservation
            application.`,
              ` Once the proposed name has been authorized, the LLP or
            limited liability partnership firm can use the approved
            name by entering it as the proposed name on the
            registration form.`,
            ],
          },
        ],
        content_img: '/imgs/business/llp.png',
        alt_img: 'TODAYFILINGS - LLP Incorporation',
      },
      {
        tab_title: 'Partnership Agreement',
        tab_content: [
          {
            content_title: 'Liability Partnership Agreement',
            content_paragraph: `You've reached the end of the process, and the Limited
          Liability Partnership Agreement has been filed.`,
            points: [
              `The main foundation or agreement that would help the LLP
             is the LLP Agreement.`,
              `This is due to the fact that it would also control the LLP's and its
             partners' shared rights and obligations.`,
            ],
          },
        ],
        content_img: '/imgs/business/partnership-agreement.png',
        alt_img: 'TODAYFILINGS - LLP Partnership Agreement',
      },
    ],
  };

  /* Document Section Data */
  const LlpDocData = {
    id: 'llp-doc',
    heading: `Limited Liability Partnership`,
    sub_heading: `Features for Limited Liability Partnership registration in
    India`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/legal.png`,
        alt_tag: `Legal Entity`,
        header: `Separate Legal Entity`,
        description: '',
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/partnership.png`,
        alt_tag: `Partnership`,
        header: `Partnership`,
        description: '',
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/partner.png`,
        alt_tag: `Partners Designation`,
        header: `Partners Designation`,
        description: '',
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/liabilities.png`,
        alt_tag: `Liabilities Partners`,
        header: `Liabilities Partners`,
        description: '',
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/frim.png`,
        alt_tag: `Partnership Company`,
        header: `Partnership Firms`,
        description: '',
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/responsibility.png`,
        alt_tag: `LLP Responsibility`,
        header: `LLP Responsibility`,
        description: '',
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Limited Liability Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={limitedSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={LLPAboutData} />

          <Counter />

          <Service item={PvtLtdComp} />

          <Document docSecData={LlpDocData} />

          <GeneralTab HTabsData={LlpPartnershipTabsData} />
          <Cta />
          <FAQAccord items={LtdFAQ} />
        </main>
      </Layout>
    </div>
  );
}
