// import { Link } from 'gatsby'
import React from "react";
import { SideBar } from "./Form";

export default function Package({ packageData }) {
  return (
    <section className='package'>
      <div className='container'>
        <div className='row'>
          <SideBar data={packageData} />

          <div className='col-lg-2 banner-arrow'>
            <img src='/imgs/arrow-with-broken-line.png' alt='Arrow design' />
          </div>

          <div
            className='col-lg-5 col-md-5 package-details'
            style={packageData.includes ? {} : { paddingTop: '10%' }}
          >
            <div className='row'>
              <div className='col-md-12'>
                <h4
                  className='text-white'
                  style={packageData.includes ? {} : { fontSize: '45px' }}
                >
                  {packageData.header}
                </h4>
              </div>
              {/* <div className='col-md-4 text-end'>
                                <Link type="submit" className="btn btn-outline-dark" to="#pricing-buy">Buy Now</Link>
                            </div> */}
            </div>
            <div className='row mt-2'>
              <div className='col-md-12 text-center'>
                <div className='d-flex align-items-center justify-content-center'>
                  {/* <h3 className='text-white m-2'> @</h3> */}
                  {packageData.f_price ? (
                    <h3 className='final-price text-white line-t m-2'>
                      ₹ {packageData.f_price} /-
                    </h3>
                  ) : (
                    ''
                  )}
                  <h3
                    className='original-price text-white m-2'
                    style={packageData.includes ? {} : { fontSize: '35px' }}
                  >
                    ₹ {packageData.price} /-
                  </h3>
                </div>
                {packageData.orginal ? (
                  <p className='original-price-text'>
                    In 15 days | From any where | At a single click{' '}
                  </p>
                ) : (
                  ''
                )}
              </div>
              {/* <div className='col-md-6 text-center'>
                                <h3 className='final-price'>₹ 2999</h3>
                                <p className='final-price-text'>Final Price (<span className='text-danger'>*</span>without GST)</p>
                            </div> */}
            </div>
            {packageData.includes ? (
              <div className='container pricing-includes'>
                <div className='row'>
                  <h6 className='pricing-includes-text'>
                    Also Get Absolutely Free
                  </h6>
                  {packageData.content.map((point, index) => (
                    <React.Fragment key={index}>
                      <div className='col-lg-3 mt-2'>
                        <div className={`icon-box ${point.background}`}>
                          <img src={point.icon} alt={point.alt_tag} />
                          <h3>{point.points}</h3>
                        </div>
                      </div>
                      <span className={`plus ${point.plus_dnone}`}>
                        {point.plus}
                      </span>
                    </React.Fragment>
                  ))}

                  {/* <div className="col-lg-3 mt-2">
                                    <div className="icon-box">
                                        <img
                                            src="/imgs/icons/gst.png"
                                            alt="TODAYFILINGS GST filing service"
                                        />
                                        <h3>PAN & TAN Registration</h3>

                                    </div>
                                </div>
                                <span className='plus'>+</span>
                                <div className="col-lg-3 mt-2">
                                    <div className="icon-box ">
                                        <img className='bg-f78acb'
                                            src="/imgs/icons/itr_filing.png"
                                            alt="TODAYFILINGS ITR E-filing service"
                                        />
                                        <h3>PF + ESIC + Professional Tax</h3>

                                    </div>
                                </div>
                                <span className='plus d-none d-md-block'>+</span>
                                <div className="col-lg-3 mt-2">
                                    <div className="icon-box">
                                        <img className='bg-eb6b3d'
                                            src="/imgs/icons/enterprises.png"
                                            alt="TODAYFILINGS - Best Enterprises Products service in Hosur"
                                        />
                                        <h3>MSME Registration</h3>

                                    </div>
                                </div>
                                <span className='plus'>+</span>
                                <div className="col-lg-3 mt-2">
                                    <div className="icon-box">
                                        <img className='bg-c679e3'
                                            src="/imgs/icons/consultant.png"
                                            alt="TODAYFILINGS - Best Enterprises Products service in Hosur"
                                        />
                                        <h3>Tax Consultancy Service</h3>

                                    </div>
                                </div> */}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='col-md-12 hero-text'>
              <span className='emi-text'>
                *Professional Fees, EMI Options Available
              </span>
            </div>

            {/* <div className='row hero-contact mt-5'>
                            <div className='col-md-6'>
                                <span className='contact-num'><i class="bi bi-envelope-fill text-white"></i> Mail:<Link to="mailto:abc@gmail.com">abc@gmail.com</Link></span>

                            </div>
                            <div className='col-md-6'>
                                <span className='contact-num'><i class="bi bi-phone text-white"></i> Call:<Link to="tel:9944246132">0987654321</Link></span>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
