import React from 'react'

export default function Document({ docSecData }) {
    console.log(docSecData);
    return (
      <section id={docSecData.id} className="efilling">
        <div className="container  mt-5">
          <div className="section-header">
            <h2>{docSecData.heading}</h2>
            <div className="bar"></div>
            <h5>{docSecData.sub_heading}</h5>
          </div>
          <div className="row">
            {docSecData.doc_contents.map((points, index) => (
              <div className={`icon-box  ${points.col}`} key={index}>
                <div className="icon">
                  <img src={points.img} alt={points.alt_tag} />
                </div>
                <div className="title">
                  <h4>{points.header}</h4>
                </div>
                <p className="description">{points.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
}
