import React, { useState } from "react";
import axios from "axios";
// import { Link } from 'gatsby'

export default function Form() {
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  let InitialData = {
    name: '',
    email: '',
    phone: '',
    // price: "3999",
    // category_name: "GST Registration",
  };
  const [formData, setFormData] = useState(InitialData);

  const handleInput = (event) => {
    const newData = { ...formData };
    newData[event.target.name] = event.target.value;
    setFormData(newData);
  };

  const baseURL = 'https://app.todayfilings.com/api/affiliate-program';
  // const baseURL = 'http://127.0.0.1:8000/api/affiliate-program';
  const Submit = (event) => {
    event.preventDefault();
    setSpinner(true);
    setDisable(true);
    const data = new FormData(event.target);
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:8000',
        'Content-Type': 'application/json',
      },
    };
    axios
      .post(baseURL, data, axiosConfig)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setShow(true);
          setTimeout(() => {
            setShow(false);
            setDisable(false);
            setSpinner(false);
          }, 3000);
          setFormData(InitialData);
        } else {
          alert('no');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className='col-lg-4 pr-10 md-mb-10'>
        <form className='mb-5' id='myForm' onSubmit={Submit}>
          <div className='mb-3 mt-4'>
            <h4 className='text-center mb-3'>Join as an Affiliate</h4>
            <label htmlFor='form-label'>Name:</label>
            <input
              type='name'
              className='form-control'
              id='name'
              placeholder='Enter Name'
              name='name'
              required
              autoComplete='off'
              value={formData.name}
              onChange={handleInput}
            />
          </div>
          <div className='mb-3 mt-3'>
            <label htmlFor='form-label'>Email:</label>
            <input
              type='email'
              className='form-control'
              id='email'
              placeholder='Enter email'
              name='email'
              required
              autoComplete='off'
              value={formData.email}
              onChange={handleInput}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='form-label'>Phone:</label>
            <input
              type='phone'
              className='form-control'
              id='phone'
              placeholder='Enter Phone Number'
              name='phone'
              required
              autoComplete='off'
              value={formData.phone}
              onChange={handleInput}
            />
          </div>
          {/* <div className="mb-3">
            <label htmlFor="form-label">Purpose:</label>
            <input
              type="text"
              className="form-control"
              id="purpose1"
              placeholder=""
              name="purpose" required autoComplete="off"
              value={formData.purpose}
              onChange={handleInput}
            />
          </div> */}
          {/* <input type="hidden" name="category_name" value={formData.category_name} />
          <div className="mb-3">
            <label htmlFor="form-label">Price:</label>
            <input
              type="text"
              className="form-control"
              id="price1"
              placeholder=""
              name="price" required autoComplete="off"
              value="1999"
              disabled
            />
          </div> */}

          <button
            type='submit'
            className='btn btn-primary'
            disabled={disable ? 'disable' : ''}
          >
            {spinner ? (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            ) : (
              <i className='bi bi-box-arrow-up'></i>
            )}
            Submit
          </button>
          {show ? (
            <p
              id='success-text'
              className='success-text text-center text-primary'
            >
              Register Successfully
            </p>
          ) : (
            ''
          )}
        </form>
      </div>
    </>
  );
}

export function SideBar({ data }) {
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  let InitialData = {
    name: '',
    email: '',
    phone: '',
    price: '',
    category_name: '',
    name_prefix: '',
    city: '',
  };
  const [formData, setFormData] = useState(InitialData);

  const handleInput = (event) => {
    const newData = { ...formData };
    newData[event.target.id] = event.target.value;
    setFormData(newData);
  };
  const name_prefix = [
    {
      value: '0',
      codeValue: `Select`,
    },
    {
      value: '1',
      codeValue: `Mr.`,
    },
    {
      value: '2',
      codeValue: `Ms.`,
    },
    {
      value: '3',
      codeValue: `Mrs.`,
    },
  ];
  const baseURL = 'https://app.todayfilings.com/api/sub-base-reg';
  // const baseURL = 'http://127.0.0.1:8000/api/sub-base-reg';
  const Submit = (event) => {
    event.preventDefault();
    setSpinner(true);
    setDisable(true);
    const data = new FormData(event.target);
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:8000',
        'Content-Type': 'application/json',
      },
    };
    axios
      .post(baseURL, data, axiosConfig)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setShow(true);
          setTimeout(() => {
            setShow(false);
            setDisable(false);
            setSpinner(false);
          }, 3000);
          setFormData(InitialData);
        } else {
          alert('no');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div id='pricing-buy' className='col-lg-4 col-md-4 d-none d-md-block '>
      <div className='row hero-contact mt-5'>
        <div className='col-md-6'>
          <span className='contact-num'>
            {/* Mail:<Link to='mailto:abc@gmail.com'>abc@gmail.com</Link> */}
          </span>
        </div>
        <div className='col-md-6'>
          <span className='contact-num'>
            {/* Call:<Link to='tel:9944246132'>0987654321</Link> */}
          </span>
        </div>
      </div>
      <div className='sidebar-item'>
        <div className='sticky'>
          {show ? (
            <p
              id='success-text'
              className='success-text text-center text-primary'
            >
              Register Successfully!!! <br /> Login details are sent your mail
              please check...
            </p>
          ) : (
            <form className='sidebar-form' onSubmit={Submit}>
              <h6 className='heading-form text-center'>
                Get Quote Instantly in a Minute
              </h6>
              <div className='bar'></div>
              <div className='row'>
                <div className='col-md-3 mb-2 pe-0'>
                  <div className='form-group'>
                    <select
                      name='name_prefix'
                      id='name_prefix'
                      className='form-control'
                      autoComplete='off'
                      value={formData.name_prefix}
                      onChange={handleInput}
                    >
                      {name_prefix.map((code, index) => (
                        <option value={code.codeValue} key={index}>
                          {code.codeValue}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-9 mb-2'>
                  {/* <label htmlFor="form-label">Name:</label> */}
                  <input
                    type='name'
                    className='form-control'
                    id='name'
                    placeholder='Enter Name'
                    name='name'
                    required
                    autoComplete='off'
                    value={formData.name}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className=' mb-2'>
                {/* <label htmlFor="form-label">Email:</label> */}
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  placeholder='Enter email'
                  name='email'
                  required
                  autoComplete='off'
                  value={formData.email}
                  onChange={handleInput}
                />
              </div>
              <div className=' mb-2'>
                {/* <label htmlFor="form-label">Phone:</label> */}
                <input
                  type='phone'
                  className='form-control'
                  id='phone'
                  placeholder='Enter Phone Number'
                  name='phone'
                  required
                  autoComplete='off'
                  value={formData.phone}
                  onChange={handleInput}
                />
              </div>
              {/* <div className="">
                    <label htmlFor="form-label">Purpose:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="purpose2"
                      placeholder=""
                      name="purpose" required autoComplete="off"
                      value={formData.purpose}
                      onChange={handleInput}
                    />
                  </div> */}

              <input type='hidden' name='category_name' value={data.header} />
              <input type='hidden' name='price' value={data.price} />

              <div className=' mb-2'>
                {/* <label htmlFor="form-label">Price:</label> */}
                <input
                  type='text'
                  className='form-control'
                  id='city'
                  name='city'
                  required
                  autoComplete='off'
                  value={formData.city}
                  placeholder='Enter City Name'
                  onChange={handleInput}
                />
              </div>
              <div className='text-center mb-2'>
                <button
                  type='submit'
                  className='side-bar-btn btn btn-primary'
                  disabled={disable ? 'disable' : ''}
                >
                  {spinner ? (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : (
                    <i className='bi bi-box-arrow-up'></i>
                  )}
                  Submit Now
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
