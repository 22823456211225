import React from "react";
// import "/src/styles/index.css";

export default function Service({ item }) {
  return (
    <section id="feature-service" className="feature-service mt-5 pt-50">
      <div div className="container">
        <div className="section-header">
          <h2>{item.header}</h2>
          <div className="bar"></div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex ">
            <div className="row  gy-4">
              <p className="text-center">{item.content}</p>
              {item.points.map((point, index) => (
                <div className="col-md-6" key={index}>
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>{point}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4 feature-service-img ">
            <img
              src={item.image}
              className="img-fluid mt-5"
              alt={item.alt_img}
            />
            <img
              src="/imgs/animation/circle-shape.png"
              className="bg-image rotateme mt-5"
              alt="TODAYFILINGS"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
