import React from "react";
import { Tab, Nav } from "react-bootstrap";

export default function GeneralTab({ HTabsData }) {
  return (
    <section id={HTabsData.id} className="features">
      <div className="container mt-5">
        <div className="section-header">
          <h2>{HTabsData.heading}</h2>
          <div className="bar"></div>
        </div>
        {/* <p className="aos-init aos-animate" data-aos="fade-up" data-aos-offset="100"> */}
        <p>{HTabsData.paragraph}</p>
      </div>
      <div className="container  htabs mt-5">
        <Tab.Container defaultActiveKey="tab0">
          <div className="row">
            <Nav variant="pills" className="">
              {HTabsData.body.map((each_tab, index) => (
                <Nav.Item className="col-12 col-md-3 col-lg-3" key={index}>
                  <Nav.Link eventKey={`tab${index}`}>
                    <h5
                      style={{
                        marginBottom: "0",
                        lineHeight: "1.5",
                      }}
                    >
                      {each_tab.tab_title}
                    </h5>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <div className="tab-content">
            <Tab.Content>
              {HTabsData.body.map((each_tab, index) => (
                <Tab.Pane eventKey={`tab${index}`} key={index}>
                  <div className="tab-pane active show" id="tab-1">
                    <div className="row gy-4">
                      <div className="col-lg-8 order-2 order-lg-1 mt-5">
                        {each_tab.tab_content.map((each_section, index1) => (
                          <div className="mt-4" key={index1}>
                            <h5>
                              <strong>{each_section.content_title}</strong>
                            </h5>
                            <p className="desc">
                              {each_section.content_paragraph}
                            </p>
                            {each_section.points.map((point, index2) => (
                              <div className="desc" key={index2}>
                                <div className="row">
                                  <div className="col-md-1">
                                    <i className="bi bi-check-circle-fill"></i>
                                  </div>
                                  <div className="col-md-11">{point}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>

                      <div className="col-lg-4 order-1 order-lg-2 text-center">
                        <img
                          src={each_tab.content_img}
                          alt={each_tab.alt_tag}
                          className="img-fluid "
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </section>
  );
}

/* Taxation tabs */

export function TdsFiling() {
  return (
    <div className="container htabs">
      <Tab.Container defaultActiveKey="tab1">
        <div className="row">
          <Nav variant="pills" className="">
            <Nav.Item className="col-12 col-md-4 col-lg-4  ">
              <Nav.Link eventKey="tab1">
                <h5>Procedure for Filing</h5>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="col-12 col-md-4 col-lg-4">
              <Nav.Link eventKey="tab2">
                <h5>Due date details</h5>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="col-12 col-md-4 col-lg-4">
              <Nav.Link eventKey="tab3">
                <h5>Penalty </h5>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="tab-content">
          <Tab.Content>
            <Tab.Pane eventKey="tab1">
              <div className="tab-pane active show" id="tab-1">
                <div className="row gy-4">
                  <div className="col-lg-1 col-md-1"></div>
                  <div className="col-lg-10  col-md-10 order-2 order-lg-1 ">
                    <p>
                      <strong>
                        The Various Forms For Filing TDS Returns According To
                        TDS Are Listed Below:
                      </strong>
                    </p>
                    <table className="table table-striped border">
                      <thead>
                        <tr>
                          <th scope="col">Dictated Form</th>
                          <th scope="col">
                            Purpose of Tax Deducted at Source or TDS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">FORM 24Q </th>
                          <td>TDS from Salary</td>
                        </tr>
                        <tr>
                          <th scope="row">FORM 26Q</th>
                          <td>TDS from all payments other than salary</td>
                        </tr>
                        <tr>
                          <th scope="row">FORM 26QC</th>
                          <td>
                            TDS for the income received as rent that exceeds
                            Rs.50,000.
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">FORM 27Q</th>
                          <td>
                            TDS from another source of income like interest from
                            any savings, Dividend funds, income from any fixed
                            deposits.
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">FORM 27EQ</th>
                          <td>The tax deducted at Source</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      A completed Form 27A must be attached with the return
                      statement for the quarterly tds dates since it serves as
                      an overview of the tds online filing deducted and
                      deposited.
                    </p>
                  </div>
                  <div className="col-lg-1 col-md-1"></div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab2">
              <div className="tab-pane " id="tab-2">
                <div className="row gy-4">
                  <div className="col-lg-1 col-md-1"></div>

                  <div
                    className="col-lg-10 order-2 order-lg-1 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p>
                      TDS Returns can be filed online, however they must be
                      submitted on or before the deadline for each quarterly
                      submission. For the 2019–20 fiscal year.
                    </p>
                    <table className="table table-striped border">
                      <thead>
                        <tr>
                          <th>Quarter</th>
                          <th>Quarter Period</th>
                          <th>Last date of filing</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>First Quarter</td>
                          <td>1st April to 30th June </td>
                          <td>31st July 2019</td>
                        </tr>
                        <tr>
                          <td>Second Quarter Of the year</td>
                          <td>1st July to 30th September</td>
                          <td>31st July 2019</td>
                        </tr>
                        <tr>
                          <td>Second Quarter Of the year</td>
                          <td>1st October to 31st December</td>
                          <td>31st January 2020</td>
                        </tr>
                        <tr>
                          <td>Second Quarter Of the year</td>
                          <td>1st January to 31st March</td>
                          <td>31st May 2019</td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      The deadline for submitting the updated TDS return for the
                      month of March that was deducted in accordance with
                      Session 1941A is April 30, 2020.
                    </p>
                  </div>
                  <div className="col-lg-1 col-md-1"></div>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab3">
              <div className="tab-pane " id="tab-3">
                <div className="row gy-4">
                  <div className="col-lg-1 col-md-1"></div>

                  <div className="col-lg-10 order-2 order-lg-1">
                    <h5>
                      Failure to submit the TDS return filing by the deadline
                      will result in penalties.
                    </h5>
                    <p>
                      <strong>
                        Any person or any entity who fails to submit the TDS
                        Return on or before the due dates as specified are
                        liable to pay the penalty or late filing fee.
                      </strong>
                    </p>
                    <h4>
                      <i className="bi bi-check-circle-fill"></i> Late TDS
                      Return Filing Under Section 234E
                    </h4>
                    <p>
                      For such tardy TDS return filing, a daily payment of Rs.
                      200 is still due. The maximum fine is the amount of the
                      income tax tds return. Before beginning the TDS Return
                      filing process, the late TDS return filing charge must be
                      paid.
                    </p>
                    <h4>
                      <i className="bi bi-check-circle-fill"></i> The Penalty As
                      Per Section 271H
                    </h4>
                    <p>
                      The penalty that the authority officer may impose on the
                      defaulter can range from a minimum of Rs. 10,000 to a
                      maximum of Rs. 1 lakh. The addition of late TDS return
                      filing fees results in the penalty noted above.
                    </p>
                  </div>
                  <div className="col-lg-1 col-md-1"></div>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
}
