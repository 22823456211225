import React from 'react'

export default function Counter() {
    const Counters = [
      {
        title: "Clients",
        count: "500",
        count_class: "plus",
      },
      {
        title: "CA/CS",
        count: "10",
        count_class: "plus",
      },
      {
        title: "Customer Rating",
        count: "4.9",
        count_class: "plus",
      },
      {
        borderRight: true,
        title: "Customer's Satisfaction",
        count: "99.9",
        count_class: "percent",
      },
    ];
    return (
      <section>
        <div className="rs-counter mt-5 style7 md-pb-70 ">
          <div className="container image">
            <div className="row couter-area">
              {Counters.map((Counter, index) => (
                <div
                  key={index}
                  className={`col-lg-3 col-md-6 sm-mb-30  ${
                    Counter.borderRight ? "" : "b-right"
                  }`}
                >
                  <div className="counter-item text-center">
                    <h2 className={`rs-count ${Counter.count_class}`}>
                      {Counter.count}
                    </h2>
                    <h4 className="title mb-0">{Counter.title}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
}
